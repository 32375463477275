<template>
  <div class="row mx-0 mb-1 position-relative">
    <slot />
    <div class="col-12 p-0">
      <div :for="id" class="form-label" v-if="label" v-html="label"></div>
      <div class="row m-0 align-items-center w-100">
        <div class="col p-0" >
          <input
            class="form-control medium"
            :class="[color, size, {error: hasErrors > 0, error: theError}]"
            type="number"
            :id="id"
            :placeholder="placeholder"
            :autocomplete="autocomplete"
            :disabled="disabled"
            v-model="value"
            min="0"
            max="100"
          >
        </div>
        <div class="col-auto pt-1 percentage medium" :class="[color, size]">
          %
        </div>
      </div>
      <div class="isError text-center" v-for="(error, index) of errors" :key="index" >
        {{ error.$message }}
      </div>
      <div class="isError text-center" v-if="theError" >
        {{ theError }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PercentageField',
  props: {
    id: {
      type: String,
      default: Math.random().toString(20).substr(2, 6)
    },
    type: String,
    placeholder: String,
    label: String,
    modelValue: String,
    size: String,
    color: String,
    errors: Array,
    hasErrors: Number,
    autocomplete: String,
    disabled: Boolean,
    theError: String
  },
  emits: ['update:modelValue'],
  watch: {
    value (val) {
      if (val < 0) {
        this.value = 0
      } else if (val > 100) {
        this.value = 100
      }
    }
  },
  computed: {
    value: {
      get () {
        return this.modelValue
      },
      set (value) {
        this.$emit('update:modelValue', value)
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .form-control {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    border-radius: 2rem;
    border: 2px solid var(--green-color-dark);
    font-size: 1rem;
    height: calc(2.5rem + 4px);
    padding: 0 1rem;
    font-weight: 500;
    text-align: left;
  }
  .form-control:disabled, .form-control[readonly] {
    background-color: var(--green-color-dark);
    opacity: 0.9;
    cursor: not-allowed;
  }
  .form-control.gold {
    color: var(--gold-color);
  }
  .form-control.white {
    color: #fff;
  }
  .form-control.outline {
    background-color: #fff;
    color: var(--normal-color);
    border: 1px solid var(--normal-color);
  }
  .form-control:focus {
    background-color: var(--green-color-dark);
    color: var(--green-color-light);
    box-shadow: none;
    border: 2px solid var(--green-color-light);
  }
  .form-control.gold:focus {
    color: var(--gold-color);
  }
  .form-control.white:focus {
    color: #fff;
  }
  .form-control.outline:focus {
    background-color: #fff;
    border: 1px solid var(--green-color-light);
  }
  .big {
    height: 4.5rem;
    border-radius: 4rem;
    font-size: 2.5rem;
    text-align: center;
    color: var(--gold-color);
  }
  .form-control.big:focus {
    color: var(--gold-color);
  }
  ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--green-color-light) !important;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--green-color-light) !important;
  }

  ::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--green-color-light) !important;
  }
  .big::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: var(--gold-color) !important;
    opacity: 1; /* Firefox */
  }
  .big:-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: var(--gold-color) !important;
  }
  .big::-ms-input-placeholder { /* Microsoft Edge */
    color: var(--gold-color) !important;
  }
  .uppercase {
    text-transform: capitalize;
  }
  .percentage {
    position: absolute;
    color: var(--gold-color);
    right: 10px;
    height: calc(2.5rem + 2px);
    line-height: 2;
    font-size: 1rem;
  }
</style>
